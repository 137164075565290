.sold-products {
    background: #1e1e1e;
    padding: 20px;
    border-radius: 8px;
    color: #fff;
  
    &__title {
      font-size: 24px;
      margin-bottom: 15px;
      text-align: center;
      color: #0099ff;
    }
  
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }
  
    &__loader {
      text-align: center;
      color: #aaa;
      font-size: 16px;
      padding: 20px;
      background: #2c2c2c;
      border-radius: 5px;
      width: 100%;
      max-width: 300px;
    }
  
    &__empty {
      text-align: center;
      color: #aaa;
      font-size: 16px;
      padding: 20px;
    }
  
    &__table-wrapper {
      width: 100%;
      overflow-x: auto; /* Горизонтальная прокрутка на маленьких экранах */
    }
  
    &__table {
      width: 100%;
      border-collapse: collapse;
      background: #2c2c2c;
      border-radius: 5px;
      overflow: hidden;
    }
  
    &__table-header {
      background: #333;
  
      th {
        padding: 12px;
        font-size: 16px;
        text-align: left;
        border-bottom: 1px solid #444;
      }
    }
  
    &__table-row {
      transition: background-color 0.3s ease;
  
      &:hover {
        background: #383838;
      }
  
      td {
        padding: 12px;
        font-size: 14px;
        color: #fff;
        border-bottom: 1px solid #333;
      }
  
      td:nth-child(4) { /* Цена со скидкой */
        color: #00ff6a;
      }
    }
  
    /* Адаптация для экранов меньше 768px */
    @media (max-width: 768px) {
      padding: 15px;
  
      &__title {
        font-size: 20px;
      }
  
      &__loader,
      &__empty {
        font-size: 14px;
        padding: 15px;
      }
  
      &__table-header th {
        font-size: 14px;
        padding: 10px;
      }
  
      &__table-row td {
        font-size: 12px;
        padding: 10px;
      }
    }
  
    /* Адаптация для экранов меньше 576px */
    @media (max-width: 576px) {
      padding: 12px;
  
      &__title {
        font-size: 18px;
      }
  
      &__loader,
      &__empty {
        font-size: 12px;
        padding: 12px;
      }
  
      &__table-header th {
        font-size: 12px;
        padding: 8px;
      }
  
      &__table-row td {
        font-size: 10px;
        padding: 8px;
      }
    }
  
    /* Адаптация для экранов меньше 480px */
    @media (max-width: 480px) {
      padding: 10px;
  
      &__title {
        font-size: 16px;
      }
  
      &__loader,
      &__empty {
        font-size: 10px;
        padding: 10px;
      }
  
      &__table-header th {
        font-size: 10px;
        padding: 6px;
      }
  
      &__table-row td {
        font-size: 9px;
        padding: 6px;
      }
    }
  }