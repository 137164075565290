.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px;
  background-color: #2c2c2c;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);

  &__input {
    font-size: 14px;
    padding: 8px 10px;
    border: 1px solid #555;
    background-color: #3a3a3a;
    color: #fff;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;

    &:focus {
      border-color: #4a90e2;
      box-shadow: 0 0 3px rgba(74, 144, 226, 0.6);
    }

    &--text {
      flex: 1 1 100%;
      max-width: 150px;
    }

    &--select {
      flex: 1 1 calc(25% - 10px); // 4 колонки на больших экранах
      max-width: 150px;
    }
  }

  /* Адаптация */
  @media (max-width: 992px) {
    &__input--select {
      flex: 1 1 calc(33.33% - 10px); // 3 колонки
      max-width: 130px;
    }
  }

  @media (max-width: 768px) {
    &__input--select {
      flex: 1 1 calc(50% - 10px); // 2 колонки
      max-width: 100%;
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: stretch;

    &__input {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
}
