.programs {
    padding: 60px 0;
    background-color: #0E0D0D;
  
    &__header {
      text-align: center;
      margin-bottom: 50px;
    }
  
    &__title {
      font-size: 36px;
      color: #ffffff;
      margin-bottom: 10px;
    }
  
    &__subtitle {
      font-size: 18px;
      color: #979797;
    }
  
    &__groups {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
      gap: 25px;
    }
  
    &__group {
      background-color: #f9f9f9;
      border-radius: 12px;
      box-shadow: 0 6px 12px rgba(0,0,0,0.08);
      overflow: hidden;
      transition: transform 0.3s;
  
      &:hover {
        transform: translateY(-5px);
      }
  
      &-img {
        width: 100%;
        height: 180px;
        object-fit: cover;
      }
  
      &-info {
        padding: 20px;
      }
  
      &-title {
        font-size: 22px;
        margin-bottom: 10px;
        color: #333;
      }
  
      &-text {
        font-size: 16px;
        color: #555;
      }
    }
  
    @media (max-width: 768px) {
      padding: 40px 0;
  
      &__title {
        font-size: 30px;
      }
  
      &__subtitle {
        font-size: 16px;
      }
    }
  
    @media (max-width: 480px) {
      padding: 30px 0;
  
      &__group-title {
        font-size: 20px;
      }
  
      &__group-text {
        font-size: 14px;
      }
    }
  }
  