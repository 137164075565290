.expiring-clients {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  
    h2 {
      font-size: 24px;
      color: #333;
      margin-bottom: 20px;
      text-align: center;
      font-weight: 600;
    }
  
    &__table {
      width: 100%;
      border-collapse: collapse;
      background: #fff;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
  
      thead {
        background: #4a90e2;
        color: #fff;
  
        th {
          padding: 15px;
          text-align: left;
          font-weight: 500;
          text-transform: uppercase;
          font-size: 14px;
        }
      }
  
      tbody {
        tr {
          border-bottom: 1px solid #eee;
          transition: background 0.2s ease;
  
          &:hover {
            background: #f5f8fa;
          }
  
          &:nth-child(even) {
            background: #fafafa;
          }
  
          td {
            padding: 15px;
            font-size: 14px;
            color: #555;
  
            &:last-child {
              font-weight: 600;
              color: #d9534f; // Красный для даты окончания
            }
          }
        }
      }
    }
  
    // Стили для загрузки и ошибок
    & > div {
      text-align: center;
      padding: 20px;
      font-size: 16px;
      color: #777;
      background: #f9f9f9;
      border-radius: 8px;
      margin-top: 20px;
    }
  }
  
  // Адаптивность
  @media (max-width: 768px) {
    .expiring-clients {
      padding: 10px;
  
      h2 {
        font-size: 20px;
      }
  
      &__table {
        font-size: 12px;
  
        th, td {
          padding: 10px;
        }
  
        // Превращаем таблицу в блоки на маленьких экранах
        thead {
          display: none; // Скрываем заголовки
        }
  
        tbody {
          tr {
            display: block;
            margin-bottom: 15px;
            border: 1px solid #ddd;
            border-radius: 8px;
  
            td {
              display: block;
              text-align: right;
              padding: 8px 15px;
              position: relative;
  
              &:before {
                content: attr(data-label); // Используем data-label для заголовков
                position: absolute;
                left: 15px;
                font-weight: 600;
                color: #333;
              }
            }
          }
        }
      }
    }
  }