.product-list {
  background: #1e1e1e; // Тёмный фон списка
  padding: 20px;
  border-radius: 8px;
  color: #fff;

  &__title {
    font-size: 24px;
    margin-bottom: 15px;
    text-align: center;
    color: #0099ff; // Желтый цвет для заголовка
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 12px;
      text-align: center;
      border: 1px solid #333;
    }

    th {
      background: #333;
      font-weight: bold;
    }

    td {
      background: #2c2c2c;
    }

    &__table-header {
      background: #333;
    }

    &__table-row {
      &:hover {
        background: #3a3a3a;
      }
    }
  }

  &__delete-button {
    background: #ff0000;
    color: #1e1e1e;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: #e60000; // Немного темнее при наведении
    }

    &:disabled {
      background: #666;
      cursor: not-allowed;
    }
  }

  // Адаптация для мобильных устройств и разных экранов
  @media (max-width: 768px) {
    padding: 15px;

    &__title {
      font-size: 20px;
    }

    &__table {
      font-size: 14px;

      th, td {
        padding: 10px;
      }
    }

    &__delete-button {
      padding: 6px 12px;
      font-size: 14px;
    }

    // Прокрутка для таблицы
    &__table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
  }

  @media (max-width: 576px) {
    padding: 10px;

    &__title {
      font-size: 18px;
      margin-bottom: 10px;
    }

    &__table {
      font-size: 12px;

      th, td {
        padding: 8px;
      }
    }

    &__delete-button {
      padding: 5px 10px;
      font-size: 12px;
    }

    // Сделать таблицу более удобной на маленьких экранах
    &__table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
  }

  @media (max-width: 480px) {
    &__table {
      font-size: 10px;

      th, td {
        padding: 6px;
      }
    }

    &__delete-button {
      padding: 4px 8px;
      font-size: 10px;
    }
  }
}
