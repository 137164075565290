.price {
    width: 100%;
    height: 100%;
    padding: 1% 1% 6% 1%;

    &__title {
        display: flex;
        justify-content: center;
        font-family: sans-serif;
        font-style: normal;
        font-weight: 800;
        line-height: 140%;
        padding-top: 5%;
        color: #1b1b1b;
    }

    &__text {
        color: var(--text, #2c2c2c);
        font-family: serif;
        font-style: normal;
        font-size: 110%;
        line-height: 140%;
        display: flex;
        justify-content: center;
    }

    &__group {
        display: flex;
        padding-top: 5%;
        gap: 14.6%;
    }

    // &__kid {
    //     &-photo {
    //         width: 100.5%;
    //         border-radius: 10%;
    //     }

    //     &-form {
    //         display: flex;
    //         flex-direction: column;

    //         &-title {
    //             display: flex;
    //             justify-content: center;
    //             font-family: serif;
    //         }

    //         &-btn {
    //             box-shadow: 0 5px 15px 0 rgba(11, 99, 246, 1);
    //             transition: 0.5s;
    //             text-transform: uppercase;
    //             text-decoration: none;
    //             padding: 6% 20%;
    //             display: inline-block;
    //             border-radius: 100px;
    //             margin: 0 auto;
    //             margin-top: 10%;
    //             border: none;

    //             &:hover {
    //                 transform: translate(0, -3px);
    //                 box-shadow: 0 20px 40px 0 rgba(11, 99, 246, 1);
    //             }
    //         }
    //     }

    //     &-table {
    //         width: 242px;
    //         margin: 0 auto;
    //         font-family: Arial, Helvetica, sans-serif;
    //         border-collapse: collapse;
    //         margin-top: 15px;

    //         td {
    //             border: 1px solid #ddd;
    //             padding: 8px;
    //         }

    //         tr {
    //             &:hover {
    //                 background-color: #ddd;
    //             }
    //         }

    //         &-tr {
    //             width: 200px;
    //             border: none;
    //             appearance: none;
    //             background-color: rgb(255, 255, 255);
    //         }
    //     }
    // }

    &__big {
        &-photo {
            width: 100%;
            border-radius: 11%;
        }

        &-form {
            display: flex;
            flex-direction: column;

            &-title {
                display: flex;
                justify-content: center;
                font-family: serif;
                text-align: center;
            }

            &-btn {
                box-shadow: 0 5px 15px 0 rgb(11, 99, 246);
                transition: 0.5s;
                text-transform: uppercase;
                text-decoration: none;
                padding: 6% 20%;
                display: inline-block;
                border-radius: 100px;
                margin: 0 auto;
                margin-top: 10%;
                border: none;
                 &:hover {
                    transform: translate(0, -3px);
                    box-shadow: 0 20px 40px 0 rgba(11, 99, 246, 1);
                }
            }
        }

        &-table {
            width: 242px;
            margin: 0 auto;
            font-family: Arial, Helvetica, sans-serif;
            border-collapse: collapse;
            margin-top: 15px;

            tr {
                &:hover {
                    background-color: #ddd;
                }
            }

            td {
                border: 1px solid #ddd;
                padding: 8px;
            }

            &-tr {
                width: 200px;
                background-color: rgb(255, 255, 255);
            }
        }
    }

    // &__vip {
    //     &-photo {
    //         width: 99%;
    //         border-radius: 11%;
    //     }

    //     &-form {
    //         display: flex;
    //         flex-direction: column;
    //     }

    //     &-title {
    //         display: flex;
    //         justify-content: center;
    //         font-family: serif;
    //     }

    //     &-table {
    //         width: 242px;
    //         margin: 0 auto;
    //         font-family: Arial, Helvetica, sans-serif;
    //         border-collapse: collapse;
    //         margin-top: 15px;

    //         td {
    //             border: 1px solid #ddd;
    //             padding: 8px;
    //         }

    //         tr {
    //             &:hover {
    //                 background-color: #ddd;
    //             }
    //         }

    //         &-tr {
    //             width: 200px;
    //             background-color: rgb(255, 255, 255);
    //         }
    //     }

    //     &-btn {
    //         box-shadow: 0 5px 15px 0 rgba(11, 99, 246, 1);
    //         transition: 0.5s;
    //         text-transform: uppercase;
    //         text-decoration: none;
    //         padding: 6% 20%;
    //         display: inline-block;
    //         border-radius: 100px;
    //         margin: 0 auto;
    //         margin-top: 10%;
    //         border: none;

    //         &:hover {
    //             transform: translate(0, -3px);
    //             box-shadow: 0 20px 40px 0 rgba(11, 99, 246, 1);
    //         }
    //     }
    // }

    &__form {
        display: flex;
        justify-content: center;
        height: 50px;

        &:hover {
            background-color: #ddd;
        }

        &-body {
            border: none;
            appearance: none;
            padding: 0 30px 0 15px;
            width: 100%;
            font-size: 13px;

            &:hover {
                background-color: #ddd
            }
        }
    }
}