  .login-form {
    max-width: 400px;
    padding: 20px;
    margin: 200px auto;
    background-color: #2c2c2c;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  
    &__title {
      text-align: center;
      font-size: 24px;
      color: #fff;
      margin-bottom: 20px;
    }
  
    &__error {
      color: red;
      text-align: center;
      margin-bottom: 10px;
    }
  
    &__input {
      width: 100%;
      padding: 12px;
      margin-bottom: 15px;
      border-radius: 5px;
      border: 1px solid #444;
      background-color: #444;
      color: #fff;
      font-size: 14px;
      display: flex;
  
      &::placeholder {
        color: #bbb;
      }
  
      &:focus {
        outline: none;
        border-color: #4caf50;
        background-color: #333;
      }
    }
  
    &__password-container {
      position: relative;
    }
  
    &__password-toggle {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border: none;
      color: #4caf50;
      font-size: 14px;
      cursor: pointer;
  
      &:hover {
        color: #fff;
      }
    }
  
    &__submit {
      width: 100%;
      padding: 12px;
      background-color: #4caf50;
      border: none;
      border-radius: 5px;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:disabled {
        background-color: #777;
        cursor: not-allowed;
      }
  
      &:hover {
        background-color: #45a049;
      }
    }
  
    &__link {
      display: block;
      text-align: center;
      margin-top: 15px;
      color: #fff;
      text-decoration: none;
  
      p {
        font-size: 14px;
        color: #bbb;
  
        &:hover {
          color: #4caf50;
        }
      }
    }
  
    // Адаптивность для разных экранов
    @media (max-width: 1024px) {
      max-width: 350px;
      padding: 18px;
    }
  
    @media (max-width: 768px) {
      max-width: 320px;
      padding: 16px;
  
      &__title {
        font-size: 22px;
      }
  
      &__input {
        padding: 10px;
        font-size: 13px;
      }
  
      &__submit {
        font-size: 15px;
      }
    }
  
    @media (max-width: 576px) {
      max-width: 280px;
      padding: 14px;
      
  
      &__title {
        font-size: 20px;
      }
  
      &__input {
        padding: 9px;
        font-size: 12px;
      }
  
      &__submit {
        font-size: 14px;
      }
    }
  
    @media (max-width: 480px) {
      max-width: 260px;
      padding: 12px;
  
      &__title {
        font-size: 18px;
      }
  
      &__input {
        padding: 8px;
        font-size: 12px;
      }
  
      &__submit {
        font-size: 13px;
      }
    }
  
    @media (max-width: 320px) {
      max-width: 230px;
      padding: 10px;
      
  
      &__title {
        font-size: 16px;
      }
  
      &__input {
        padding: 7px;
        font-size: 11px;
      }
  
      &__submit {
        font-size: 12px;
      }
    }
  }

  .registration-form {
    max-width: 400px;
    margin: 200px auto;
    padding: 20px;
    background-color: #2c2c2c;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  
    &__title {
      text-align: center;
      font-size: 24px;
      color: #fff;
      margin-bottom: 20px;
    }
  
    &__errors {
      color: red;
      margin-bottom: 1em;
  
      p {
        margin: 0;
      }
    }
  
    &__input,
    &__select {
      width: 100%;
      padding: 12px;
      margin-bottom: 15px;
      border-radius: 5px;
      border: 1px solid #444;
      background-color: #444;
      color: #fff;
      font-size: 14px;
  
      &::placeholder {
        color: #bbb;
      }
  
      &:focus {
        outline: none;
        border-color: #4caf50;
        background-color: #333;
      }
    }
  
    &__button {
      width: 100%;
      padding: 12px;
      background-color: #4caf50;
      border: none;
      border-radius: 5px;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:disabled {
        background-color: #777;
        cursor: not-allowed;
      }
  
      &:hover {
        background-color: #45a049;
      }
    }
  
    &__link {
      display: block;
      text-align: center;
      margin-top: 15px;
      color: #fff;
      text-decoration: none;
  
      p {
        font-size: 14px;
        color: #bbb;
  
        &:hover {
          color: #4caf50;
        }
      }
    }
  
    // Адаптация под разные экраны
    @media (max-width: 1024px) {
      max-width: 350px;
      padding: 18px;
    }
  
    @media (max-width: 768px) {
      max-width: 320px;
      padding: 16px;
  
      &__title {
        font-size: 22px;
      }
  
      &__input,
      &__select {
        padding: 10px;
        font-size: 13px;
      }
  
      &__button {
        font-size: 15px;
      }
    }
  
    @media (max-width: 576px) {
      max-width: 280px;
      padding: 14px;
  
      &__title {
        font-size: 20px;
      }
  
      &__input,
      &__select {
        padding: 9px;
        font-size: 12px;
      }
  
      &__button {
        font-size: 14px;
      }
    }
  
    @media (max-width: 480px) {
      max-width: 260px;
      padding: 12px;
  
      &__title {
        font-size: 18px;
      }
  
      &__input,
      &__select {
        padding: 8px;
        font-size: 12px;
      }
  
      &__button {
        font-size: 13px;
      }
    }
  
    @media (max-width: 320px) {
      max-width: 230px;
      padding: 10px;
  
      &__title {
        font-size: 16px;
      }
  
      &__input,
      &__select {
        padding: 7px;
        font-size: 11px;
      }
  
      &__button {
        font-size: 12px;
      }
    }
  }