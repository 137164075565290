.admin-panel {
  display: flex;
  background-color: #1a1a1a;
  min-height: 100vh; // Гарантированная высота панели
}

.custom-sidebar {
  width: 220px;
  background-color: #0b0b0b;
  color: #dcdcdc;
  padding: 20px;
  height: 100vh;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: width 0.3s ease;

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  &__item {
    width: 100%;
    padding: 15px 20px;
    margin-bottom: 10px;
    font-size: 16px;
    color: #b3b3b3;
    background-color: transparent;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;

    &:hover {
      background-color: #1a1a1a;
      color: #ffffff;
      transform: translateX(5px); // Лёгкое смещение при наведении
    }

    &--active {
      background-color: #151515;
      color: #ffffff;
      font-weight: bold;
      box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.1);
    }
  }
}

.admin-panel__content {
  flex: 1;
  background-color: #181818;
  color: #d9d9d9;
  padding: 20px;
  border-left: 1px solid #2a2a2a;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.7);
  transition: padding 0.3s ease;
}

// Адаптивность для планшетов и мобильных устройств
@media (max-width: 1024px) {
  .custom-sidebar {
    width: 180px;

    &__item {
      font-size: 14px;
      padding: 12px 16px;
    }
  }
}

@media (max-width: 768px) {
  .admin-panel {
    flex-direction: column;
  }

  .custom-sidebar {
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.8);

    &__list {
      display: flex;
      width: 100%;
      justify-content: space-around;
      z-index: 2;
    }

    &__item {
      flex: 1;
      text-align: center;
      font-size: 14px;
      padding: 10px;
      border-radius: 5px;
    }
  }

  .admin-panel__content {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .custom-sidebar {
    &__item {
      font-size: 12px;
      padding: 8px;
    }
  }

  .admin-panel__content {
    padding: 10px;
  }
}
@media (max-width: 320px) {
  .custom-sidebar {
    overflow-x: auto; // только горизонтальная прокрутка
    &__list {
      display: flex;
      flex-wrap: nowrap; // элементы в одну строку
      width: max-content; // для корректной прокрутки
    }
  }
}

