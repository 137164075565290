
.follow {
    text-align: center;
    padding: 50px 0;
    background-color: #0E0D0D;
    color: white;
  
    &__title {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  
    &__net {
      display: flex;
      justify-content: center;
      gap: 20px;
  
      &-link {
        text-decoration: none;
        transition: transform 0.3s ease-in-out;
  
        &:hover {
          transform: scale(1.2);
        }
      }
  
      &-icon {
        font-size: 40px;
        color: white;
        transition: color 0.3s ease-in-out;
  
        &:hover {
          color: #ff5722;
        }
      }
    }
  
    @media (max-width: 768px) {
      &__title {
        font-size: 24px;
      }
  
      &__net {
        gap: 15px;
        
        &-icon {
          font-size: 35px;
        }
      }
    }
  
    @media (max-width: 480px) {
      padding: 30px 0;
  
      &__title {
        font-size: 20px;
      }
  
      &__net {
        gap: 10px;
  
        &-icon {
          font-size: 30px;
        }
      }
    }
  }
  