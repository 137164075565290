.we {
    padding: 60px 0;
    background-color: #f9f9f9;
  
    &__header {
      text-align: center;
      margin-bottom: 40px;
    }
  
    &__title {
      font-size: 36px;
      color: #222;
      margin-bottom: 10px;
    }
  
    &__subtitle {
      font-size: 18px;
      color: #666;
    }
  
    &__cards {
      display: flex;
      justify-content: space-between;
      gap: 20px;
  
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
    }
  
    &__card {
      background-color: #fff;
      padding: 25px;
      border-radius: 10px;
      box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
      text-align: center;
      flex: 1;
      transition: transform 0.3s;
  
      &:hover {
        transform: translateY(-10px);
      }
  
      &-icon {
        width: 60px;
        height: 60px;
        margin-bottom: 20px;
      }
  
      &-title {
        font-size: 20px;
        margin-bottom: 15px;
        color: #333;
      }
  
      &-text {
        font-size: 16px;
        color: #555;
      }
    }
  
    @media (max-width: 480px) {
      padding: 30px 0;
  
      &__title {
        font-size: 28px;
      }
  
      &__subtitle {
        font-size: 16px;
      }
  
      &__card {
        padding: 20px;
  
        &-title {
          font-size: 18px;
        }
  
        &-text {
          font-size: 14px;
        }
      }
    }
  }
  