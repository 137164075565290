.admin-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
  background-color: #1e1e1e;

  .admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #2a2a2a;
    border-bottom: 1px solid #333;

    .manager-name {
      color: #d9d9d9;
      font-size: 14px;
      font-weight: 400;
    }

    .logout-btn {
      background: #333;
      border: none;
      color: #fff;
      font-size: 14px;
      padding: 6px 12px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background: #555;
      }
    }
  }

  .top-nav {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    background-color: #2a2a2a;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
    overflow-x: auto;
    white-space: nowrap;
    gap: 15px;
    scroll-behavior: smooth;

    .nav-item {
      color: #d9d9d9;
      font-size: 16px;
      font-weight: 600;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;
      flex-shrink: 0;

      &:hover {
        background-color: #3b3b3b;
      }

      &.active {
        background-color: #4a90e2;
        color: #ffffff;
        box-shadow: 0 2px 8px rgba(74, 144, 226, 0.6);
      }
    }
  }

  @media (max-width: 768px) {
    .admin-header {
      padding: 8px 10px;
    }

    .top-nav {
      justify-content: flex-start;
      padding: 10px;
      scrollbar-width: none;
      -ms-overflow-style: none;
      z-index: 2;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .main-content {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background-color: #2c2c2c;
    border-radius: 10px;
    margin: 20px auto;
    max-width: 1200px;
    width: calc(100% - 40px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);

    h2 {
      font-size: 24px;
      margin-bottom: 20px;
      color: #f1f1f1;
    }

    p {
      font-size: 16px;
      line-height: 1.5;
      color: #cccccc;
    }
  }
}