.total-income {
    background-color: #1e1e2f; // Темный фон
    color: #f5f5f5; // Светлый текст
    padding: 20px;
    border-radius: 10px;
    max-width: 700px;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    position: relative;
    z-index: 1;
  
    h1 {
      font-size: 26px;
      text-align: center;
      margin-bottom: 20px;
      color: #ffcc00; // Акцентный цвет для заголовка
    }
  
    &__filters {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin-bottom: 20px;
      justify-content: space-between;
  
      select {
        flex: 1 1 calc(33.33% - 10px); // Три элемента в ряд
        min-width: 140px;
        padding: 8px 12px;
        font-size: 14px;
        border: 1px solid #444;
        background-color: #2a2a3d;
        color: #f5f5f5;
        border-radius: 5px;
        outline: none;
        transition: border-color 0.2s ease, box-shadow 0.2s ease;
  
        &:hover {
          border-color: #ffcc00;
        }
  
        &:focus {
          box-shadow: 0 0 5px #ffcc00;
        }
      }
    }
  
    .income-result {
      text-align: center;
      background-color: #2a2a3d;
      padding: 15px 20px;
      border-radius: 5px;
  
      h2 {
        font-size: 22px;
        font-weight: bold;
        color: #2ecc71; // Зеленый цвет для акцента
      }
    }
  }
  