.client-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
  color: #ddd;

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 16px;
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__input,
  &__select,
  &__textarea {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #444;
    border-radius: 8px;
    background: #2a2a2a;
    color: #eee;
    transition: border-color 0.3s, box-shadow 0.3s;

    &:focus {
      border-color: #4a90e2;
      outline: none;
      box-shadow: 0 0 6px rgba(74, 144, 226, 0.8);
    }
  }

  &__textarea {
    resize: none;
    height: 50px;
    font-family: 'Roboto', sans-serif;
  }

  &__button {
    width: 100%;
    padding: 14px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background: #4a90e2;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
    margin-top: 5%;

    &:hover {
      background: #357abd;
      transform: scale(1.02);
    }
  }

  // Адаптация для разных экранов
  @media (max-width: 768px) {
    max-width: 90%;
    padding: 16px;

    &__input,
    &__select,
    &__textarea {
      font-size: 14px;
      padding: 10px;
    }

    &__button {
      font-size: 15px;
      padding: 12px;
    }
  }

  @media (max-width: 480px) {
    max-width: 100%;
    padding: 12px;

    &__grid {
      grid-template-columns: 1fr;
      gap: 12px;
    }

    &__input,
    &__select,
    &__textarea {
      font-size: 13px;
      padding: 9px;
    }

    &__button {
      font-size: 14px;
      padding: 10px;
    }
  }

  @media (max-width: 320px) {
    padding: 10px;

    &__input,
    &__select,
    &__textarea {
      font-size: 12px;
      padding: 8px;
    }

    &__button {
      font-size: 13px;
      padding: 9px;
    }
  }
}
