.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: all 0.3s ease;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    position: relative;
  }

  &__logo-link {
    display: block;
    text-decoration: none;
  }

  &__nav-logo {
    height: 70px;
    width: auto;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__nav-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    display: none;
    color: #333;
    transition: all 0.3s ease;

    &:hover {
      color: #007bff;
      transform: rotate(90deg);
    }
  }

  &__nav-menu {
    &.active {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }

  &__nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }

  &__nav-item {
    position: relative;
  }

  &__nav-link {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 12px;
    transition: all 0.3s ease;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 50%;
      background: #007bff;
      transition: all 0.3s ease;
      transform: translateX(-50%);
    }

    &:hover {
      color: #007bff;

      &::after {
        width: 100%;
      }
    }
  }
}

// Responsive Design
@media (max-width: 768px) {
  .header {
    &__nav-button {
      display: block;
    }

    &__nav-menu {
      position: fixed;
      top: 104px;
      right: 0;
      width: 100%;
      max-width: 300px;
      height: calc(100vh - 80px);
      background: rgba(255, 255, 255, 0.98);
      transform: translateX(100%);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      padding: 20px;
      box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
    }

    &__nav-list {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    &__nav-link {
      display: block;
      width: 100%;
      padding: 15px 20px;
      
      &::after {
        display: none;
      }

      &:hover {
        background: rgba(0, 123, 255, 0.1);
        border-radius: 8px;
      }
    }
  }
}

@media (max-width: 480px) {
  .header {
    &__nav-logo {
      height: 40px;
    }

    &__nav-menu {
      top: 70px;
    }
  }
}