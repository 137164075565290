.whatsapp-chat {
  display: flex;
  height: 100vh;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #e5ddd5;
  position: relative;

  .burger-menu {
    position: absolute;
    top: 7px;
    left: 10px;
    z-index: 100;
    background: #075e54;
    color: #fff;
    border: none;
    padding: 4px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #128c7e;
    }
  }

  .sidebar {
    height: 100%;
    width: 300px; // фиксированная ширина для десктопной версии
    background-color: #fff;
    border-right: 1px solid #ddd;
    overflow-y: auto;
    transition: transform 0.3s ease;

    h3 {
      padding: 12px;
      margin: 0;
      border-bottom: 1px solid #f0f0f0;
      color: #075e54;
      font-size: 18px;
      text-align: center;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        border-bottom: 1px solid #f0f0f0;
        transition: background-color 0.3s ease;

        &:hover,
        &.active {
          background-color: #ebebeb;
        }

        .avatar {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          margin-right: 8px;
        }

        .chat-name {
          font-size: 14px;
          font-weight: bold;
          color: #333;
        }
      }
    }
  }

  .chat-window {
    flex: 1; // занимает оставшееся пространство
    display: flex;
    flex-direction: column;
    background-color: #efe7de;

    .chat-header {
      background-color: #075e54;
      color: #fff;
      padding: 12px;
      padding-left: 50px;
      font-size: 16px;
      font-weight: bold;
      display: flex;
      align-items: center;
    }

    .chat-messages {
      flex-grow: 1;
      padding: 10px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .message {
        max-width: 80%;
        padding: 8px 12px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        position: relative;

        &.incoming {
          align-self: flex-start;
          background-color: #fff;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }

        &.outgoing {
          align-self: flex-end;
          background-color: #dcf8c6;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }

        .message-info {
          font-size: 12px;
          font-weight: bold;
          color: #555;
          margin-bottom: 4px;
        }

        .message-content {
          font-size: 14px;
          color: #333;
        }

        .message-meta {
          display: flex;
          justify-content: space-between;
          font-size: 11px;
          color: #888;
          margin-top: 4px;
        }

        img,
        video,
        audio {
          max-width: 100%;
          border-radius: 6px;
          margin-top: 4px;
        }

        a {
          color: #075e54;
          text-decoration: none;
        }
      }
    }

    .chat-input {
      padding: 8px;
      display: flex;
      border-top: 1px solid #ddd;
      background-color: #f0f0f0;

      input {
        flex-grow: 1;
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 20px;
        margin-right: 8px;
        outline: none;
        font-size: 14px;
      }

      button {
        background-color: #075e54;
        color: #fff;
        border: none;
        padding: 6px 14px;
        border-radius: 20px;
        cursor: pointer;
        font-size: 12px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #128c7e;
        }
      }
    }

    .select-chat {
      padding: 15px;
      text-align: center;
      color: #666;
      font-size: 16px;
    }
  }

  // Медиа-запросы для мобильной версии (до 768px)
  @media (max-width: 768px) {
    .burger-menu {
      display: block;
    }

    .sidebar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: translateX(-100%);
      z-index: 50;

      &.open {
        transform: translateX(0);
      }
    }

    .chat-window {
      width: 100%;
    }
  }

  // Медиа-запросы для десктопной версии (от 769px)
  @media (min-width: 769px) {
    .burger-menu {
      display: none;
    }

    .sidebar {
      position: relative; // возвращаем в нормальный поток
      transform: translateX(0); // всегда видно
    }
  }
}