.companye {
    padding: 60px 0;
    background-color: #f9f9f9;
    color: #222;
  
    &__title {
      text-align: center;
      font-size: 36px;
      font-weight: 800;
      margin-bottom: 15px;
    }
  
    &__subtitle {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: #666;
      margin-bottom: 30px;
    }
  
    &__text {
      font-size: 16px;
      line-height: 1.8;
      text-align: justify;
      max-width: 900px;
      margin: 0 auto 20px;
      color: #444;
    }
  
    @media (max-width: 768px) {
      padding: 40px 0;
  
      &__title {
        font-size: 30px;
      }
  
      &__subtitle {
        font-size: 18px;
      }
  
      &__text {
        font-size: 15px;
        line-height: 1.6;
      }
    }
  
    @media (max-width: 480px) {
      padding: 30px 10px;
  
      &__title {
        font-size: 26px;
      }
  
      &__subtitle {
        font-size: 16px;
      }
  
      &__text {
        font-size: 14px;
      }
    }
  }
  